.inputStyleExtender {
  & label {
    text-transform: none;
    font-weight: 500;
    color: var(--color-blue-dark);
    font-size: 20px;
  }
}

.textArea {
  & div {
    height: 125px;
  }
}
