.invoiceStyleExtender {
  & p {
    font-size: 18px;
    margin: 10px 0;
    color: #bb4949;
  }

  & div {
    & :first-child {
      font-size: 20px;
      font-weight: 500;
    }

    & :last-child {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
