.inputStyleExtender {
  & input {
    padding-left: 75px;
  }
}

.selectForm {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, 19.5px);
  z-index: 1;
  width: 70px;

  & select {
    border-radius: 15px 0 0 15px;
  }
}
